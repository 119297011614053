<template>
  <div class="section-content section-pickup pt-4 pb-5 w-100 container">
    <b-container class="container-xlg m-auto pl-xxl-0 pr-xxl-0 about-content">
      <div class="top-event d-flex flex-column flex-md-column">
        <esports-title
          title="ABOUT"
          class="pb-2 pt-2 mb-2 mt-2 mt-xxl-4 pb-xxl-5 article-page"
          sub_title="Donate+eSportsとは"
        />
      </div>
      <SectionImgAbout />

      <!-- <SectionAboutTitle class="mt-3 mt-md-5">
            <p class="about-footer">
               <span class="font-weight-bold">運営会社</span><br />
               <span class="d-inline-block">会社名</span><span>株式会社メルメクス</span><br />
               <span class="d-inline-block">住所</span><span>107-0062　東京都港区南青山6-8-10-2F</span><br />
               <span class="d-inline-block">設立</span><span>2005年4月</span><br />
               <span class="d-inline-block">資本金</span><span>20,000,000円</span><br />
               <span class="d-inline-block">事業内容</span><span>Donate + eSportsの運営</span><br />
               <span class="d-inline-block">代表者</span><span>森雄作</span><br />
               <span class="d-inline-block">連絡先</span><span><a href="mailto:info@melmax.co.jp">info@melmax.co.jp</a></span>
            </p>
      </SectionAboutTitle> -->
    </b-container>
  </div>
</template>

<script>
import SectionImgAbout from './SectionImgAbout';

export default {
    name: "SectionPrivacy",
    components: { SectionImgAbout },
    data() {
          return {
                URL_FRONT_END:  process.env.VUE_APP_FRONT_END
           }
    }

}
</script>