<template>
  <div class="section-img-about">
    <div class="txt-donate pb-2 mb-2">
      <span class="title d-block d-md-inline-block">Donate</span>
      <span class="txt-content d-block d-md-inline-block txt-donate"
        >寄付する　寄贈する</span
      >
    </div>
    <div class="txt-donate mb-3 pb-2">
      <span class="title d-block d-md-inline-block">E-Sports</span>
      <span class="txt-content d-block d-md-inline-block"
        >エレクトロニック・スポーツの略　主にコンピューターなどの
        <br class="d-none d-md-block" />電子機器を使った対戦ゲームをスポーツ競技として捉える際の名称</span
      >
    </div>
    <p>
      「Donate + eSports」は「選手を応援してeSportsを盛り上げよう！」をコンセプトに、eSportsのプレイヤーを応援するサービスです。具体的にはeSportsの大会に参加するプレイヤーの中から優勝者を予想をし、予想が的中した方の中から抽選でAmazonギフトに交換できるポイント（サービス内ではドネトと呼んでいます）をプレゼントします。プレイヤーには本サービスより配信へのギフト・ビッツ・スーパーチャットやサブスクリプション登録等々で応援していきます。
    </p>
    <p class="mb-lg-4 pb-4">
      予想するためには投票券を取得する必要があります。投票券は大会情報のリツイートや広告の利用、日々のログイン、友達の紹介などを通じて付与しています。<br />
      一人の選手だけを応援してもいいですし、複数の選手を応援しても構いません。見事に優勝者を的中した方の中からドネトと呼ばれる、サービス内の通貨をお渡しします。3000以上のドネトを貯めると、Amazonギフトに交換（1ドネト＝1Amazonギフト）が可能となります。
    </p>

    <b-img class="d-none d-md-block" fluid-grow :src="require('@/assets/images/about-img.jpg')"></b-img>
    <b-img class="d-block d-md-none" fluid-grow :src="require('@/assets/images/about-img-mobile.jpg')"></b-img>
  </div>
</template>

<script>
export default {
  name: "SectionImgAbout",
};
</script>